
.container {
  color: #777;
}

.drive-list {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
}
.drive div {
  display: inline;
  padding: 5px;
  padding-right: 15px;
}
.spacer {
  padding-bottom: 20px;
}
.drive-date {
  color: green;
  font-weight: bold;
  font-style: italic;
}
.list-button {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}

/* media queries */
@media only screen and (max-width: 768px) {
  .drive-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 800px) {
  .drive-list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1100px) {
  .drive-list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
