body {
  background-color: #efefef;
}

nav {
  width: 100%;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 3px;
  background-color: #607181;
  color: #fdf4c7;
}
nav a {
  color: #fdf4c7;
  text-decoration: none;
}
nav a:hover {
  color: cyan;
}
.brand img {
	margin-right: 0px;
}
.brand {
  color: #fdf4c7;
  font-family: helvetica,sans-serif;
  font-size: 1.5rem;
  padding-left: 10px;
}
.brand:hover {
  color: cyan;
}
.nav-item {
  margin-left: 0px;
}
.nav-item a, .NavLink a {
  padding: 5px;
  color: #fdf4c7;
  border: solid #607181 1px;
  text-decoration: none;
}
.nav-item a:hover {
  color: cyan;
  border: solid cyan 1px;
}
.nav-item a.active {
  color: white;
  border-bottom: 2px solid white;
}
.nav-link {
  margin: 10px;
}

.btn-standard {
  color: white;
  background-color: #3a4d73;
  border: solid cyan 1px;
}
.btn-standard:hover {
  background-color: lightblue;
  color: #3a4d73;
  border: solid darkblue 1px;
}
.btn-standard a {
  color: white;
  background-color: #3a4d73;
  text-decoration: none;
}
.btn-standard  a:hover{
  color: #3a4d73;
  background-color: lightblue;
}

.wrapper {
  margin: 20px auto;
  width: 80%;
  text-align: center;
}

.footer {
  width: 100%;
  margin: 0;
  padding: 20px;
  background-color: #607181;
  color: #fdf4c7;
}

