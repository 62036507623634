
.form-div {
  width: 85%;
  margin: 20px auto;
  text-align: center;
}
label {
  padding: 15px;
}
input {
  margin-right: 10px;
}
input.num {
  width: 60px;
}
.submit-div {
  width: 85%;
  text-align: center;
}
