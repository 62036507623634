
.container {
  color: #777;
}

.home-h4 {
  color: darkred;
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
}
.todo-result {
  width: 100%;
  padding-bottom: 0px;
}
.todo-title {
  color: maroon;
}
.todo-title span {
  color: green;
  margin-left: 50px;
}
.current-result {
  width: 100%;
  text-align: center;
  padding-bottom: 0px;
}
.current-result a {
  padding: 0;
  margin: 0;
  color: green;
  text-decoration: none;
  font-weight: bold;
}
.current-result a:hover {
  color: white;
  background-color: green;
}
.current-drive {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.drive-list {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.drive div {
  display: inline;
  padding: 5px;
  padding-right: 15px;
}
.spacer {
  padding-bottom: 20px;
}
.drive-date a {
  color: darkblue;
  font-weight: bold;
  font-style: italic;
  text-decoration: none;
}
.drive-date a:hover {
  color: cyan;
}
.list-button {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}
