body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

input {
  outline: none;
}

.home h1{
  text-align: center;
}

.home .panel {
  width: 600px;
  display: flex;
  justify-content: center;
  margin: 30px auto;
}

.home .panel a {
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: 30px;
  box-shadow: #333 0 0 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  color: #333;
}

.home .panel a:hover{
  color: #fff;
  background: #333;
}

.login-form,
.register-form,
.profile {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  margin: 30px auto;
  padding: 30px 60px;
  box-shadow: #333 0 0 10px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
}

.login-form label,
.register-form label,
.profile label {
  display: block;
  width: 100%;
  padding: 5px;
}

.login-form input,
.register-form input {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #333;
  background: #fff;
}

.login-form button,
.register-form button {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  border: 1px solid #333;
  background: #333;
  color: #fff;
  cursor: pointer;
}

.login-form button:hover,
.register-form button:hover {
  background: #fff;
  color: #333;
}

.login-form a,
.register-form a,
.profile a {
  display: block;
  width: 100%;
  margin-top: 30px;
  color: #ec8313;
  font-weight: 700;
}
