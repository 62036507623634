
.drive-card {
  width: 40%;
  margin: 20px auto;
  text-align: center;
  color: darkblue;
  background-color: lightgrey;
  border: solid lightblue 1px;
  border-radius: 40px;
  padding-bottom: 30px;
}
.drive-card h4 {
  color: #607181;
  font-style: italic;
  font-weight: bold;
}
.drive-card h6 {
  width: 100%;
  color: white;
  background-color: #607181;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 40px;
}

.drive-date {
  width: 100%;
  color: green;
  padding-bottom: 10px;
}
.drive-card p {
  font-size: 1.1rem;
  line-height: 1.4;
  color: #3a4d73;
  margin: 0px;
  padding: 0px;
}
.drive-card p span {
  font-size: 1.0rem;
  color: darkred;
  font-weight: bold;
}
.condition {
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  border: solid #999 1px;
}


/* media queries */
@media only screen and (max-width: 768px) {
  .drive-card {
    width: 97%;
  }
}

@media only screen and (min-width: 800px) {
  .drive-card {
    width: 80%;
  }
}

@media only screen and (min-width: 1100px) {
  .drive-card {
    width: 40%;
  }
}
